/* Fonts */

@font-face {
  font-family: "Droid Sans";
  src: local("Droid Sans"),
    url("./assets/fonts/DroidSans.ttf") format("truetype");
}

@font-face {
  font-family: "Droid Sans Bold";
  src: local("Droid Sans Bold"),
    url("./assets/fonts/DroidSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Permanent Marker";
  src: local("Permanent Marker"),
    url("./assets/fonts/PermanentMarker-Regular.ttf") format("truetype");
}

/* variables */

:root {
  --teal: #22b9a4;
  --purple: #7722a7;
  --lightblue: #6ab9d5;
  --peach: #ffdab9;
}

/* Keyframes */

@keyframes rainbow {
  0% {
    border-color: red;
  }
  14% {
    border-color: orange;
  }
  28% {
    border-color: yellow;
  }
  42% {
    border-color: green;
  }
  57% {
    border-color: blue;
  }
  71% {
    border-color: indigo;
  }
  85% {
    border-color: violet;
  }
  100% {
    border-color: red;
  }
}

/* Default style */

body {
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2 {
  font-family: "Permanent Marker", cursive;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

h2,
h3,
p {
  text-align: left;
}

h2.pad,
h3.pad,
p.pad {
  padding-left: 20px;
}

div.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main App Component */

.App {
  text-align: center;
  font-family: Drone Sans, sans-serif;
  font-size: calc(10px + 2vmin);
  background-color: #234078;
  color: white;
  overflow: hidden;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  height: 18vh;
}

.App-header h1 {
  font-size: 3rem;
  font-weight: bold;
  padding-left: 20px;
}

.App-header #logo {
  height: 15rem;
}

.homeHeader {
  font-size: 5.5rem;
}

/* Main Section */

.Main-section {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.Main-section nav {
  display: flex;
  align-items: start;
  justify-content: start;
  font-family: "Permanent Marker", cursive;
}

.Main-section nav a > div {
  position: relative;
  overflow: hidden;
}

.Main-section nav a > div:hover {
  cursor: pointer;
  transform: scale(1.05);
  color: var(--peach);
}

.Main-section nav a > div::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background: var(--lightblue);
  transition: width 0.3s ease, left 0.3s ease, transform 0.3s ease;
}

.Main-section nav a > div:hover::after {
  transform: scaleX(1);
}

/* Nav */

.quarter-circle-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-color: var(--purple);
  border-bottom-left-radius: 100px;
  transition: width 0.3s, height 0.3s;
  overflow: hidden;
  z-index: 20;
}

.quarter-circle-nav.open {
  width: 25vw;
  height: 65vh;
  z-index: 20;
}

.nav-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 25;
}

.nav-links {
  display: none;
  z-index: 20;
}

.quarter-circle-nav.open .nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.main-links {
  padding: 1rem;
}

.main-links ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.news-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/* Light classes */

.light {
  width: 22vw;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light > span {
  font-size: 2.6rem;
  font-weight: bold;
  color: #fff;
  font-family: "Permanent Marker", cursive;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.light > span:hover {
  color: var(--peach);
}

h2.light:hover {
  cursor: pointer;
  transform: scale(1.05);
  color: var(--peach);
}

/* Divider */

.divider {
  margin: 5vh auto;
  width: 80%;
  height: 1px;
  background-color: #fff;
}

.dividerAbout,
.dividerCamps,
.dividerBoxOffice,
.dividerAuditions {
  height: 3px;
}

.dividerAbout {
  background-color: var(--purple);
}

.dividerCamps {
  background-color: var(--teal);
}

.dividerBoxOffice {
  background-color: var(--lightblue);
}

.dividerAuditions {
  background-color: var(--peach);
}

/* Sections */

.about-title {
  background: url("./assets/images/titleAbout.svg");
}

.camp-title {
  background: url("./assets/images/titleCamp.svg");
}

.office-title {
  background: url("./assets/images/titleOffice.svg");
}

.audition-title {
  background: url("./assets/images/titleAudition.svg");
}

.exaggerated-title {
  position: relative;
  font-family: "Permanent Marker", cursive;
  font-size: 4em;
  color: white;
  padding: 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  z-index: 2;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.exaggerated-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.news h1 {
  font-size: 4rem;
}

div.newsItems {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

div.homeLinks {
  display: flex;
  justify-content: center;
  gap: 5rem;
  margin-bottom: 1rem;
}

div.homeLink {
  display: inline-block;
  font-family: "Permanent Marker", cursive;
  padding: 10px 20px;
  background-color: var(--purple); /* Background color */
  text-decoration: none;
  color: white;
  position: relative; /* Position relative for pseudo-element */
  transition: background-color 0.3s, color 0.3s;
}

div.homeLink::before {
  content: "";
  position: absolute;
  top: -4px; /* Adjust according to border thickness */
  left: -4px; /* Adjust according to border thickness */
  right: -4px; /* Adjust according to border thickness */
  bottom: -4px; /* Adjust according to border thickness */
  border: 4px solid var(--purple); /* Inner border color */
  border-radius: 15px; /* Match the border radius */
  pointer-events: none; /* Ensure it doesn't interfere with clicks */
}

div.homeLink:hover {
  background-color: var(--peach); /* Change background on hover */
  color: black; /* Change text color on hover */
}

div.homeLink:hover::before {
  border-color: var(--peach); /* Change border color on hover */
}

.about h2 span,
.camps h2 span,
.auditions h2 span,
.boxOffice h2 span {
  padding: 0 10px;
}

.about h2 span {
  background-color: var(--purple);
}

.camps h2 span {
  background-color: var(--teal);
}

.about p,
.about h3 {
  margin: 1.5rem;
}

.boxOffice h2 span {
  background-color: var(--lightblue);
}

.auditions h2 span {
  background-color: var(--peach);
}

div.camp,
div.shows,
div.auditions {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

/* Classes/Camps */

div.camp {
  margin: 0 auto;
  width: 95vw;
}

div.camplist {
  display: grid;
  grid-template-areas:
    "classtitle classtitle classtitle classtitle"
    "classage1 classage2 classage3 classage4"
    "classinfo classinfo classinfo classinfo";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  gap: 1rem;
}

div.classtitle {
  grid-area: classtitle;
  grid-column: span 4;
}

div.classage1 {
  grid-area: classage1;
}

div.classage2 {
  grid-area: classage2;
}

div.classage3 {
  grid-area: classage3;
}

div.classage4 {
  grid-area: classage4;
}

div.classinfo {
  grid-area: classinfo;
  padding: 1rem;
}

.showtimes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.showtimes ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.showInfo {
  text-align: left;
  display: flex;
  width: 60vw;
}

.showInfo > .info-background {
  background-color: var(--purple);
  border-radius: 10px;
}

.showInfo > ul {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  gap: 1rem;
}

.showInfo > ul > li > span {
  font-weight: bold;
  text-decoration: underline;
}

.showLink {
  position: relative;
  display: inline-block;
  padding: 1rem;
  background: var(--lightblue);
  border-radius: 10px;
  transition: background, color 0.5s ease;
  width: 15vw;
}

.showLink:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: var(--peach);

  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}

.showLink:hover {
  background: var(--peach);
  color: black;
}

.showLink:hover:before {
  transform: scaleX(1);
}

.credits {
  font-size: 3rem;
}

/* Auditions */

div.productions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  gap: 1rem;
  width: 95vw;
}

div.production-list {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
}

div.production-list button,
div.production > a,
div.tickets > a {
  display: inline-block;
  font-family: "Permanent Marker", cursive;
  font-size: 2.5rem;
  padding: 10px 20px;
  margin-bottom: 1rem;
  background-color: var(--purple); /* Background color */
  text-decoration: none;
  color: white;
  position: relative; /* Position relative for pseudo-element */
  transition: background-color 0.3s, color 0.3s;
}

div.production-list button::before,
div.production > a::before,
div.tickets > a::before {
  content: "";
  position: absolute;
  top: -4px; /* Adjust according to border thickness */
  left: -4px; /* Adjust according to border thickness */
  right: -4px; /* Adjust according to border thickness */
  bottom: -4px; /* Adjust according to border thickness */
  border: 4px solid var(--purple); /* Inner border color */
  border-radius: 15px; /* Match the border radius */
  pointer-events: none; /* Ensure it doesn't interfere with clicks */
}

div.tickets > a {
  color: var(--lightblue);
}

div.production-list button:hover,
div.production-list button:focus,
div.production > a:hover,
div.production > a:focus,
div.tickets > a:hover,
div.tickets > a:focus {
  background-color: var(--peach); /* Change background on hover */
  color: black; /* Change text color on hover */
}

div.production-list button:hover::before,
div.production-list button:focus::before,
div.production > a:hover::before,
div.production > a:focus::before,
div.tickets > a:hover::before,
div.tickets > a:focus::before {
  border-color: var(--peach); /* Change border color on hover */
}

.footer div.newAddress a:hover,
.footer div.newAddress a:focus {
  transform: scale(1.05); /* Slightly increase the size */
  color: white;
}

.footer div.newAddress a {
  font-family: Permanent Marker, cursive;
  font-size: 2.5rem;
  cursor: pointer;
  background: none; /* Remove background */
  color: var(--teal); /* Set text color to white */
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  text-decoration: none; /* Remove default text decoration */
  position: relative; /* Positioning for the pseudo-element */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.footer div.newAddress a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px; /* Height of the underline */
  bottom: 0;
  left: 50%;
  background: white; /* Color of the underline */
  transition: width 0.3s ease, left 0.3s ease, transform 0.3s ease; /* Add transform to the transition */
}

div.tickets > a::after {
  background: white;
}

.footer div.newAddress a:hover::after {
  width: 100%;
  left: 0; /* Align to the left on hover/focus */
}

div.production-list > h3 {
  font-family: Permanent Marker, cursive;
}

div.production-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.production-item h3 {
  margin: 0.5rem 0;
  font-family: Permanent Marker, cursive;
}

div.production-item > button.production-link {
  text-align: left;
}

div.production-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

div.production-info > button {
  text-align: left;
}

div.production-info img {
  width: 60rem;
  height: 25rem;
  border-radius: 10px;
}

/* Buttons */

div.btn-news {
  font-size: calc(1rem + 2vmin);
}

div.btn-news a {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff;
  color: #234078;
  border: #fff solid 2px;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.1s ease;
}

div.btn-news a:hover {
  background-color: var(--lightblue);
  border-radius: 5px;
  border: #fff solid 4px;
  color: #fff;
}

/* Modal */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s ease-in-out;
}
.modal-content {
  background: white;
  color: black;
  display: grid;
  grid-template-columns: repeat(4, minmax(18vw, 22vw));
  justify-content: center;
  gap: 1.5rem;
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-height: 75vh;
  text-align: left;
  position: relative;
}

div.modal-content div {
  padding: 2rem;
}

div.modal-content.col-2 {
  grid-template-columns: 30% 65%;
}

.modal-content h2 {
  color: var(--teal);
}

.modal-content h3 {
  font-family: Permanent Marker, cursive;
}

.modal-content p {
  padding: 0;
}

.modal-content a {
  color: var(--lightblue);
}

.close-msg {
  position: absolute;
  top: 1%;
  left: 5%;
  font-size: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.modal-msg {
  font-size: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
}

/* Bat Boy */

.batBoy {
  background: black;
  width: 95vw;
  margin: 0 auto; /* Center the div */
  background-size: cover;
  box-shadow: inset 0 0 0 10px #fff;
  display: flex;
  flex-direction: row;
}

.batBoy img {
  width: 30vw;
  height: 30vw;
}

.batBoy div {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.batBoy h1 {
  font-size: 8rem;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  letter-spacing: 0.5rem;
}

.batBoy h2 {
  font-size: 4rem;
  font-family: "Drone Sans Bold", sans-serif;
}

/* Footer */

.footer {
  color: white;
  display: grid;
  grid-template-columns: 33vw 30vw 30vw;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  height: 30vh;
  width: 100%;
  margin: 0 auto;
}

.footer div.newAddress a {
  font-family: Permanent Marker, cursive;
  font-size: 2.75rem;
  cursor: pointer;
  background: none; /* Remove background */
  color: var(--teal); /* Set text color to white */
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  text-decoration: none; /* Remove default text decoration */
  position: relative; /* Positioning for the pseudo-element */
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.footer div.newAddress a:hover,
.footer div.newAddress a:focus {
  transform: scale(1.05); /* Slightly increase the size */
  color: white; /* Change text color on hover/focus */
}

.footer div.newDonate {
  margin: 2vh auto;
}

.footer div.newDonate input {
  display: inline-block;
  padding: 10px 20px;
  font-family: Permanent Marker, cursive;
  font-size: 2.75rem;
  background-color: var(--teal); /* Background color */
  border: 4px solid transparent; /* Initial border color */
  border-radius: 15px; /* Rounded corners */
  outline: none; /* Remove outline */
  text-decoration: none;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.footer div.newDonate input:hover {
  background-color: var(--lightblue); /* Change background on hover */
  color: #fff; /* Change text color on hover */
  animation: rainbow 2s infinite; /* Apply rainbow animation */
}

/* Social Icons - footer */

div.social {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
}

.footer .socialIcons {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.socialIcons img {
  width: 6rem;
  height: 6rem;
  transition: transform 0.3s ease, filter 0.3s ease;
  display: inline-block;
}

.socialIcons img:hover {
  transform: scale(1.1);
  filter: brightness(4);
}

.socialIcons img {
  width: 4rem;
  height: 4rem;
}

/************************************************************************************************************************************************  */
/* Mobile Optimization */
/* For mobile phones: */
@media only screen and (max-width: 600px) {
  .App {
    font-size: calc(8px + 2vmin);
  }

  .App-header {
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }

  .App-header div {
    flex: 0.1;
  }

  .App-header #logo {
    height: 8rem;
  }

  .homeHeader {
    font-size: 3rem;
  }

  .Main-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .Main-section nav {
    flex-direction: column;
  }

  .news h1 {
    font-size: 2rem;
  }

  div.newsItems {
    flex-direction: column;
  }

  .light {
    width: 100vw;
    height: 5vh;
  }

  .light > span {
    font-size: 1.1rem;
  }

  div.btn {
    font-size: 0.9rem;
  }

  .quarter-circle-nav {
    top: auto;
    left: 50%;
    bottom: 0;
    width: 40vw;
    height: 10vh;
    border-bottom-left-radius: 0;
    border-top-left-radius: 50vw;
    border-top-right-radius: 50vw;
    transform: translateX(-50%);
  }

  .quarter-circle-nav.open {
    width: 100vw;
    height: 100vh;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 20;
  }

  .nav-toggle {
    position: absolute;
    bottom: 0;
    left: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 25;
  }

  .quarter-circle-nav.open .nav-toggle {
    bottom: 10vh;
    height: 10vh;
    width: 10vw;
    border-radius: 0;
    background-color: transparent;
  }

  .quarter-circle-nav.open .nav-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .quarter-circle-nav.open img {
    object-fit: contain;
    width: 100vw;
  }

  .exaggerated-title {
    font-size: 2em;
  }

  .about-title {
    background: none;
  }

  .camp-title {
    background: none;
  }

  .office-title {
    background: none;
  }

  .audition-title {
    background: none;
  }

  section.auditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  div.productions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
  }

  div.production-list {
    flex-direction: column;
    align-items: center;
  }

  div.production-list button,
  div.production > a {
    font-size: 1.25rem;
  }

  div.production-list h2 {
    text-align: center;
  }

  div.production-item {
    justify-content: center;
    align-items: center;
  }

  div.production-item > h3 {
    margin: 0 auto;
  }

  div.production-item > button.production-link {
    text-align: center;
  }

  div.production h2 {
    text-align: center;
  }

  div.production > a::after {
    display: none;
  }

  div.production-info > button {
    text-align: center;
  }

  div.production-info img {
    width: 100%;
    border-radius: 0;
    height: 15rem;
  }

  div.auditions {
    width: 100%;
  }

  .modal-content {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 0;
    overflow-y: auto;
  }

  .modal-content h2 {
    text-align: center;
  }

  .modal-content img {
    width: 75%;
    margin: 0 auto;
  }

  div.modal-content.col-2 {
    grid-template-columns: 1fr;
  }

  div.modal-content div {
    max-width: 80%;
    padding: 1rem;
  }

  div.batBoy {
    display: block;
  }
  div.batBoy img {
    width: 100%;
    height: 100%;
  }

  div.batBoy h1 {
    font-size: 4rem;
    text-align: center;
  }

  div.batBoy h2 {
    font-size: 3rem;
  }

  div.batBoy div {
    padding: 1rem;
  }

  .credits {
    font-size: 2rem;
  }

  div.showInfo {
    width: auto;
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  a.showLink {
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  .showLink {
    width: 50vw;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    height: 100%;
  }

  .footer div.newAddress a {
    font-size: 1.5rem;
  }

  .footer div.newDonate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer div.newDonate input {
    font-size: 1.25rem;
  }

  div.homeLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  div.camplist {
    grid-template-areas:
      "classtitle"
      "classage1"
      "classage2"
      "classage3"
      "classage4"
      "classinfo";
    grid-template-columns: 1fr;
  }
  div.production-list button,
  div.production > a,
  div.tickets > a {
    font-size: 1.5rem; /* Smaller font size */
    padding: 8px 16px; /* Adjust padding */
  }
}

@media only screen and (max-width: 400px) {
  div.btn {
    font-size: 0.8rem;
  }

  .quarter-circle-nav {
    top: auto;
    left: 50%;
    bottom: 0;
    width: 30vw;
    border-bottom-left-radius: 0;
    border-top-left-radius: 50vw;
    border-top-right-radius: 50vw;
    transform: translateX(-50%);
  }

  .quarter-circle-nav.open {
    width: 100vw;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 20;
  }

  .quarter-circle-nav.open img {
    object-fit: contain;
    width: 100vw;
  }

  .nav-toggle {
    position: fixed;
    bottom: 0;
    left: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 25;
  }

  .quarter-circle-nav.open .nav-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-title {
    background: none;
  }

  .camp-title {
    background: none;
  }

  .office-title {
    background: none;
  }

  .audition-title {
    background: none;
  }

  div.auditions {
    width: 100%;
  }

  div.newsItems {
    flex-direction: column;
  }

  .modal-content {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 0;
    overflow-y: auto;
  }

  div.modal-content div {
    max-width: 80%;
    padding: 1rem;
  }

  .footer {
    flex-direction: column;
    gap: 2vh;
    height: 100%;
  }

  .footer div.newAddress a {
    font-size: 1.25rem;
  }

  .socialIcons img {
    width: 3rem;
    height: 3rem;
  }
  div.camplist {
    grid-template-areas:
      "classtitle"
      "classage1"
      "classage2"
      "classage3"
      "classage4"
      "classinfo";
    grid-template-columns: 1fr;
  }
  div.production-list button,
  div.production > a,
  div.tickets > a {
    font-size: 1.5rem; /* Smaller font size */
    padding: 8px 16px; /* Adjust padding */
  }
}

/* For tablets: */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .App {
    font-size: calc(9px + 2vmin);
  }

  .quarter-circle-nav.open .nav-links {
    flex-direction: column;
    width: 100%;
  }

  .quarter-circle-nav.open {
    width: 100vw;
    height: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: 20;
  }

  .nav-toggle {
    position: absolute;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 25;
  }

  .news h1 {
    font-size: 3rem;
  }

  div.newsItems {
    flex-direction: column;
  }

  .App-header #logo {
    height: 10rem;
  }

  .socialIcons img {
    width: 4rem;
    height: 4rem;
  }

  .light > span {
    font-size: 1.5rem;
    margin: 0 auto;
  }

  div.btn {
    font-size: 1.2rem;
  }

  .about-title {
    background: none;
  }

  .camp-title {
    background: none;
  }

  .office-title {
    background: none;
  }

  .audition-title {
    background: none;
  }

  div.productions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
  }

  div.production-list {
    flex-direction: column;
    align-items: center;
  }

  div.production-list h2 {
    text-align: center;
    font-size: 2.5rem;
  }

  div.production-item {
    justify-content: center;
    align-items: center;
  }

  div.production-item h3 {
    margin: 0 auto;
    font-size: 2rem;
  }

  div.production-item > button.production-link {
    text-align: center;
  }

  div.production h2 {
    font-size: 2.75rem;
    text-align: center;
  }

  div.production-info img {
    width: fit-content;
    height: 15rem;
  }

  div.auditions {
    width: 100%;
  }

  .modal-content {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 0;
    overflow-y: auto;
  }

  .modal-content h2 {
    text-align: center;
  }

  .modal-content img {
    width: 75%;
    margin: 0 auto;
  }

  div.modal-content.col-2 {
    grid-template-columns: 1fr;
  }

  div.modal-content div {
    max-width: 80%;
    padding: 1rem;
  }

  div.batBoy {
    display: block;
  }
  div.batBoy img {
    width: 100%;
    height: 100%;
  }

  div.batBoy h1 {
    font-size: 4rem;
    text-align: center;
  }

  div.batBoy h2 {
    font-size: 3rem;
  }

  div.batBoy div {
    padding: 1rem;
  }

  .credits {
    font-size: 2rem;
  }

  div.showInfo {
    width: auto;
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  a.showLink {
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  .showLink {
    width: 50vw;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    height: 100%;
  }

  .footer div.newAddress a {
    font-size: 2rem;
  }

  .footer div.newDonate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer div.newDonate input {
    font-size: 1.25rem;
  }

  div.homeLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  div.camplist {
    grid-template-areas:
      "classtitle"
      "classage1"
      "classage2"
      "classage3"
      "classage4"
      "classinfo";
    grid-template-columns: 1fr;
  }
  div.production-list button,
  div.production > a,
  div.tickets > a {
    font-size: 1.5rem; /* Smaller font size */
    padding: 8px 16px; /* Adjust padding */
  }
}

/* For larger tablets: */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .App-header #logo {
    width: 100vw;
    height: 100%;
  }

  .quarter-circle-nav.open .nav-links {
    flex-direction: column;
    width: 100%;
  }

  .quarter-circle-nav.open {
    width: 40vw;
    height: 50vh;
    z-index: 20;
  }

  .App-header {
    flex-direction: column;
    height: 100%;
  }

  .light > span {
    font-size: 1.8rem;
    margin: 0 auto;
  }

  .about-title {
    background: none;
  }

  .camp-title {
    background: none;
  }

  .office-title {
    background: none;
  }

  .audition-title {
    background: none;
  }

  section.auditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  div.productions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
  }

  div.production-list {
    flex-direction: column;
    align-items: center;
  }

  div.production-list {
    flex-direction: column;
    align-items: center;
  }

  div.production-list h2 {
    text-align: center;
  }

  div.production-item {
    justify-content: center;
    align-items: center;
  }

  div.production-item h3 {
    margin: 0 auto;
  }

  div.production h2 {
    text-align: center;
  }

  div.production-info img {
    width: fit-content;
    height: 15rem;
  }

  div.production-item > button.production-link,
  div.production-info > button {
    text-align: center;
  }

  div.auditions {
    width: 100%;
  }

  .modal-content {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 0;
    overflow-y: auto;
  }

  .modal-content h2 {
    text-align: center;
  }

  .modal-content img {
    width: 75%;
    margin: 0 auto;
  }

  div.modal-content.col-2 {
    grid-template-columns: 1fr;
  }

  div.modal-content div {
    max-width: 80%;
    padding: 1rem;
  }

  div.batBoy {
    display: block;
  }
  div.batBoy img {
    width: 100%;
    height: 100%;
  }

  div.batBoy div {
    padding: 1rem;
  }

  div.batBoy h1 {
    font-size: 4.5rem;
    text-align: center;
  }

  div.batBoy h2 {
    font-size: 3.25rem;
  }

  .credits {
    font-size: 2rem;
  }

  div.showInfo {
    width: auto;
    font-size: 1.8rem;
    padding: 0.5rem;
  }

  a.showLink {
    font-size: 1.2rem;
    padding: 0.5rem;
  }

  .showLink {
    width: 50vw;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    height: 100%;
  }

  .footer div.newAddress a {
    font-size: 2rem;
  }

  .footer div.newDonate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer div.newDonate input {
    font-size: 2rem;
  }

  div.homeLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  div.camplist {
    grid-template-areas:
      "classtitle classtitle"
      "classage1 classage2"
      "classage3 classage4"
      "classinfo classinfo";
    grid-template-columns: 1fr 1fr;
  }
}

/* For smaller desktop/laptops */

@media (min-width: 1024px) and (max-width: 1439px) {
  .App-header #logo {
    height: 12rem;
  }

  .quarter-circle-nav.open .nav-links {
    flex-direction: row;
    width: 100%;
  }

  .quarter-circle-nav.open .nav-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .quarter-circle-nav.open {
    width: 75vw;
  }

  .light > span {
    font-size: 2.2rem;
    margin: 0 auto;
  }

  .App-header {
    height: 20vh;
  }

  div.productions,
  div.auditions {
    width: 100%;
  }

  div.production-info img {
    width: fit-content;
    height: 15rem;
  }

  .footer {
    gap: 10vw;
    height: 100%;
  }

  .footer div.newAddress a {
    font-size: 2rem;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 2rem;
    height: 100%;
    width: 100vw;
  }

  .footer div.newAddress a {
    font-size: 2rem;
  }

  .footer div.newDonate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer div.newDonate input {
    font-size: 1.25rem;
  }

  div.homeLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

/* For desktop/laptops */
@media (min-width: 1440px) and (max-width: 1600px) {
  .App-header #logo {
    height: 12rem;
  }

  .light > span {
    font-size: 2.4rem;
    margin: 0 auto;
  }

  .App-header {
    height: 20vh;
  }

  div.productions {
    width: 100%;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 2vh;
    height: 100%;
  }
}

/* For large desktops */
@media (min-width: 1601px) {
  .App-header {
    height: 22vh;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vh;
    height: 100%;
  }
}
